/**
 * NavigationOverlay component to handle rendering of Navigation Menu.
 *
 * @module views/components/NavigationOverlay
 * @memberof -Common
 */
import './NavigationOverlay.scss';

import React, { useEffect, useRef, useState } from 'react';

import { FocusScope } from '@react-aria/focus';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import AsyncComponent from '@ulta/core/components/AsyncComponent/AsyncComponent';
import Icon from '@ulta/core/components/Icon/Icon';
import Link_Huge from '@ulta/core/components/Link_Huge/Link_Huge';
import Modules from '@ulta/core/components/Modules/Modules';
import Text from '@ulta/core/components/Text/Text';
import UseKeyPress from '@ulta/core/hooks/useKeyPress/UseKeyPress';
import { useDeviceInflection } from '@ulta/core/providers/InflectionProvider/InflectionProvider';
import { isServer } from '@ulta/core/utils/device_detection/device_detection';
import { handleEmptyObjects } from '@ulta/core/utils/handleEmptyObjects/handleEmptyObjects';

import { constants } from '@ulta/utils/constants/constants';
import {
  extractLastWord,
  extractTextWithoutLastWord,
  flyoutWidth,
  isNavigationOverlayFeaturedContent,
  isOnlyNavigationLinksSubGroup,
  isRightPanel
} from '@ulta/utils/navUtils/navUtils';

import Link from '../Link/Link';
import { getTopBarCurrentPosition, useTopBar } from '../TopBar/TopBar';
import * as utils from './NavigationOverlay';

const { ENTER_KEY } = constants;

/**
    * Represents a NavigationOverlay component
    *
    * @method
    * @param {NavigationOverlayProps} props - React properties passed from composition
    * @returns NavigationOverlay
    */
export const NavigationOverlay = React.forwardRef( ( props, _ ) => {
  const { viewAllAction, modules, dismissLabel } = props;
  const {
    menuNavigation,
    activePrimaryLinks,
    setActivePrimaryLinks,
    activeNavigationLinksGroup,
    setActiveNavigationLinksGroup,
    primaryScrollTop,
    keyBoardUser
  } = useTopBar();
  const { inflection, breakpoint } = useDeviceInflection();
  const isLargeDevice = breakpoint?.isLargeDevice();
  const isMobile = inflection.MOBILE;
  const leftNavigation = props.isLeftNavigation;
  const leftFlyoutRef = useRef();
  const rightFlyoutRef = useRef();
  const leftNavCloseButtonRef = useRef();
  const rightNavCloseButtonRef = useRef();
  const widthRef = useRef();
  const backdropRef = useRef();
  const navigationOverlayRef = useRef();
  const activePrimaryLinksRef = useRef();
  const activeNavigationLinksGroupRef = useRef();
  const [navigationOverlayHeight, setNavigationOverlayHeight] = useState();
  const [calculatedFlyoutWidth, setCalculatedFlyoutWidth] = useState( );
  const layoutRef = leftNavigation ? leftFlyoutRef : rightFlyoutRef;
  const ariaLabel = leftNavigation ? activePrimaryLinks : activeNavigationLinksGroup;

  let textWithoutLastWord, lastWord;
  if( isMobile && viewAllAction.label ){
    textWithoutLastWord = extractTextWithoutLastWord( viewAllAction.label );
    lastWord = extractLastWord( viewAllAction.label );
  }

  // TODO: Move to exported helper
  useEffect( () => {
    if( !widthRef.current || !activeNavigationLinksGroup ){
      return;
    }
    setCalculatedFlyoutWidth( flyoutWidth( { element: widthRef.current } ) );
  }, [activeNavigationLinksGroup] );

  useEffect( ( )=> {
    utils.handleScroll( {
      activePrimaryLinks,
      activePrimaryLinksRef,
      activeNavigationLinksGroup,
      activeNavigationLinksGroupRef,
      leftFlyoutRef,
      rightFlyoutRef,
      navigationOverlayRef,
      isLargeDevice
    } );

    return utils.overlayInteractionHandler( { backdropRef }, { setActiveNavigationLinksGroup, setActivePrimaryLinks } );
  }, [activePrimaryLinks, activeNavigationLinksGroup] );

  useEffect( ()=>{
    return utils.setInitialFocus(
      { keyBoardUser, leftFlyoutRef, isLargeDevice, navigationOverlayRef },
      { setNavigationOverlayHeight }
    );
  }, [activePrimaryLinks] );


  /*
  To set the focus to cross button initially when rightNavFlyout open
  */
  useEffect( ()=>{
    keyBoardUser && rightNavCloseButtonRef.current?.firstChild.focus();
  }, [activeNavigationLinksGroup] );

  /*
  For Keyboard users to return the Focus to ActivePrimaryLinks when leftFlyout is closed
  */
  UseKeyPress( ENTER_KEY, leftNavCloseButtonRef, () => utils.firstTierClose( { setActivePrimaryLinks } ), [] );

  /*
  For Keyboard users to return the Focus to ActiveNavigationLink when rightFlyout is closed
  */
  UseKeyPress( ENTER_KEY, rightNavCloseButtonRef, ()=>{
    const activeItem = activeNavigationLinksGroup?.replaceAll( /[^A-Z0-9]/ig, '_' );
    const focusableItem = document.querySelector( `.NavigationLinksGroup__${activeItem}` );
    setActiveNavigationLinksGroup( null );
    focusableItem?.focus();
  }, [] );

  if( !modules && !viewAllAction?.label ){
    return null;
  }

  return (
    <div className={
      classNames( 'NavigationOverlay', {
        'NavigationOverlay__slideTierOneInFromZero' : isMobile && activePrimaryLinks && menuNavigation?.forward,
        'NavigationOverlay__slideToSecondTier' : isMobile && activeNavigationLinksGroup && menuNavigation?.forward,
        'NavigationOverlay__slideToThirdTier' : primaryScrollTop > 0 && isMobile && activeNavigationLinksGroup && menuNavigation?.forward
      } )
    }
    { ...( leftNavigation && { 'role':'dialog' } ) }
    { ...( leftNavigation && { 'aria-modal':'true' } ) }
    ref={ navigationOverlayRef }
    { ...( navigationOverlayHeight && !isMobile && { 'style': { height: navigationOverlayHeight } } ) }
    { ...( isMobile && { 'style': { marginTop: `${primaryScrollTop}px` } } ) }

    >

      <div className='NavigationOverlay__wrapper'>
        <nav
          className={ classNames( 'NavigationOverlay__navigation', {
            [`NavigationOverlay__navigation--equalLinksOverlay`]: modules?.length === 1
          } ) }
          aria-label={ ariaLabel }
        >
          { leftNavigation && isLargeDevice &&
          <div className='NavigationOverlay__backdrop'
            ref={ backdropRef }
          >
          </div> }
          <FocusScope
            contain={ true }
            disabled={ isMobile || activeNavigationLinksGroup }
            group='NavigationOverlay__firstTier'
          >
            <div className={
              classNames( {
                'NavigationOverlay__flyoutMenuShow NavigationOverlay__flyoutMenu': !leftNavigation && isLargeDevice,
                'NavigationOverlay__sidenavMenu NavigationOverlay__leftNavShow': leftNavigation && isLargeDevice
              } )
            }
            ref={ layoutRef }
            { ...( navigationOverlayHeight && !isMobile && { 'style': { height: navigationOverlayHeight } } ) }
            >
              {
                leftNavigation &&
                <>
                  {
                    isLargeDevice &&
                    <div className='NavigationOverlay__colorSlideout'>
                    </div>
                  }
                  <Modules
                    modules={ props.modules }
                  />
                </>

              }

              {
              // if module does not contain primary or secondary displayType then everything will be in right navigation overlay
                inflection.DESKTOP ? (
                  !leftNavigation &&
                  <div
                    className={ `NavigationOverlay__item` }
                  >
                    <div className='NavigationOverlay__rightNavSection'>
                      {
                        viewAllAction?.label &&
                          <div className='NavigationOverlay__rightNavSectionTitle'>
                            <Link
                              linkAction={ viewAllAction }
                              linkIcon='ArrowForward'
                              linkStyle='title-6'
                              aria-label={ viewAllAction.label }
                              htmlTag='h2'
                            />
                          </div>
                      }
                      <div
                        className={ classNames( `NavigationOverlay__rightNavSectionWrapper`, {
                          [`NavigationOverlay--withNavigationOverlayFeaturedContent`]: isNavigationOverlayFeaturedContent( modules ) && isLargeDevice,
                          [`NavigationOverlay--withoutNavigationOverlayFeaturedContent`]: !isNavigationOverlayFeaturedContent( modules ) && isLargeDevice
                        } ) }
                      >

                        <ul
                          ref={ widthRef }
                          className={ classNames( `NavigationOverlay__rightNavSectionNavItems`, {
                            [`NavigationOverlay--${ calculatedFlyoutWidth }`]: calculatedFlyoutWidth && isLargeDevice,
                            [`NavigationOverlay--withNavigationOverlayFeaturedContent`]: isNavigationOverlayFeaturedContent( modules ) && isLargeDevice,
                            [`NavigationOverlay--onlyNavigationSubLinksGroup`]: isOnlyNavigationLinksSubGroup( { modules } ) && isLargeDevice
                          } ) }
                        >
                          {
                            modules?.map( ( module, index ) => {
                              return (
                                !isRightPanel( { moduleName: module.moduleName } ) &&
                                  <li className='NavigationOverlay__rightNavSectionItems'
                                    key={ index }
                                  >

                                    <AsyncComponent
                                      key={ `${module.id}:${index}` }
                                      { ...module }
                                    />
                                  </li>
                              );
                            } )
                          }
                          {
                            breakpoint.CURRENT_BREAKPOINT !== 'LG' &&
                              <li className='NavigationOverlay__rightNavSectionItems'>
                                {
                                  modules?.map( ( module, index ) => {
                                    return (

                                      isRightPanel( { moduleName: module.moduleName } ) &&
                                      <div className='NavigationOverlay__featuredSectionItem'
                                        key={ index }
                                      >

                                        <AsyncComponent
                                          key={ `${module.id}:${index}` }
                                          { ...module }
                                        />
                                      </div>
                                    );
                                  } )
                                }
                              </li>
                          }

                        </ul>

                        {
                          breakpoint.CURRENT_BREAKPOINT === 'LG' &&
                            <div className='NavigationOverlay__featuredSection'>
                              {
                                modules?.map( ( module, index ) => {
                                  return (

                                    isRightPanel( { moduleName: module.moduleName } ) &&
                                    <div className='NavigationOverlay__featuredSectionItem'
                                      key={ index }
                                    >

                                      <AsyncComponent
                                        key={ `${module.id}:${index}` }
                                        { ...module }
                                      />
                                    </div>
                                  );
                                } )
                              }
                            </div>
                        }

                      </div>
                    </div>
                  </div>
                ) : (
                  !leftNavigation &&
                  <li className='NavigationOverlay__mobileNavigation'>
                    {
                      viewAllAction?.label &&
                      <div className='NavigationOverlay__mobileNavigationTitle'>
                        <Link_Huge
                          action={ viewAllAction }
                        >
                          { /* Spilting the text to match invision design in Mobile if there are more than one word */ }
                          <Text
                            textStyle='title-4'
                            htmlTag='span'
                          >
                            { textWithoutLastWord }
                            { lastWord && <>&nbsp;</> }
                            {
                              lastWord ? (
                                <span>
                                  { extractLastWord( viewAllAction.label ) }
                                  <Icon
                                    className='NavigationLink__icon'
                                    name='ArrowForward'
                                    size='m'
                                    ariaHidden={ true }
                                  />
                                </span>
                              ) : null
                            }
                          </Text>
                        </Link_Huge>
                      </div>
                    }

                    {
                      modules?.map( ( i, index ) => (
                        <div className='NavigationOverlay__mobileNavigationItemEmpty'
                          key={ index }
                        >
                          <AsyncComponent
                            { ...i }
                          />
                        </div>
                      ) )
                    }

                  </li>
                ) }

            </div>
          </FocusScope>
        </nav>
      </div>
    </div>
  );
} );

/**
 * Property type definitions
 * @typedef composeNavigationOverlayHeight
 * @param {methods} methods
 * @param {function} methods.setNavigationOverlayHeight setNavigationOverlayHeight
 * @param {function} methods.getTopBarCurrentPosition getTopBarCurrentPosition
*/
export const composeNavigationOverlayHeight = ( methods ) => {
  const { setNavigationOverlayHeight, getTopBarCurrentPosition } = methods || {};
  getTopBarCurrentPosition && setNavigationOverlayHeight( `calc(100vh - ${getTopBarCurrentPosition()}px)` );
};

/**
 * firstTierClose method
 * @method
 * @param {object} methods Methods
 * @param {function} methods.setActivePrimaryLinks setActivePrimaryLinks
*/
export const firstTierClose = ( methods ) => {
  const { setActivePrimaryLinks } = methods || {};
  if( !setActivePrimaryLinks ){
    return null;
  }
  setActivePrimaryLinks( null ) ;
  document.body.classList.remove( 'leftNavFlyoutOpen' );
};

/**
 * handleScroll method
 * @method
 * @param {data} data data
*/
export const handleScroll = ( data ) => {
  const {
    activePrimaryLinksRef,
    activeNavigationLinksGroup,
    activeNavigationLinksGroupRef,
    activePrimaryLinks,
    leftFlyoutRef,
    rightFlyoutRef,
    navigationOverlayRef,
    isLargeDevice
  } = handleEmptyObjects( data );

  // Reset scroll for left flyout if activePrimaryLinks changed and it is a large device
  if( activePrimaryLinksRef?.current !== activePrimaryLinks && isLargeDevice && leftFlyoutRef.current ){
    leftFlyoutRef.current.scrollTop = 0;
  }
  // Reset scroll for right flyout if activeNavigationLinksGroup changed and it is a large device
  activePrimaryLinksRef.current = activePrimaryLinks;
  if( activeNavigationLinksGroupRef?.current !== activeNavigationLinksGroup && isLargeDevice && rightFlyoutRef.current ){
    rightFlyoutRef.current.scrollTop = 0;
  }
  // Reset scroll for navigationOverlay if activeNavigationLinksGroup is false
  if( !activeNavigationLinksGroupRef.current && navigationOverlayRef?.current ){
    navigationOverlayRef.current.scrollTop = 0;
  }
  activeNavigationLinksGroupRef.current = activeNavigationLinksGroup;
};

/**
 * overlayInteraction method
 * @method {object} argument method object
 * @param {object} data object
 * @param {object} methods object
*/
export const overlayInteractionHandler = ( data, methods )=>{
  const { backdropRef } = handleEmptyObjects( data );
  const { setActiveNavigationLinksGroup, setActivePrimaryLinks } = methods || {};

  if( isServer() ){
    return;
  }

  const closeFlyout = ( e )=> {
    if( backdropRef.current && backdropRef.current.contains( e.target ) ){
      setActiveNavigationLinksGroup( null );
      setActivePrimaryLinks( null );
      document.body.classList.remove( 'leftNavFlyoutOpen' );
    }
  };

  document.addEventListener( 'click', closeFlyout );

  return ()=> {
    document.removeEventListener( 'click', closeFlyout );
  };
};


/**
 * overlayInteraction method
 * @method {method}
 * @param {object} data - arguments
*/
export const setInitialFocus = ( data, methods )=>{
  const { isLargeDevice, navigationOverlayRef } = handleEmptyObjects( data );
  const { setNavigationOverlayHeight } = methods || {};

  if( isServer() ){
    return;
  }

  if( !isLargeDevice ){
    const navigationOverlayHeightResize = () => {
      if( navigationOverlayRef?.current ){
        navigationOverlayRef.current.style.height = `${window.innerHeight}px`;
      }
    };

    window.addEventListener( 'resize', navigationOverlayHeightResize );
    navigationOverlayHeightResize();
    return ()=>{
      window.removeEventListener( 'resize', navigationOverlayHeightResize );
    };
  }
  else {
    utils.composeNavigationOverlayHeight( { setNavigationOverlayHeight, getTopBarCurrentPosition } );
  }
};

/**
 * Property type definitions
 *
 * @typedef NavigationOverlayProps
 * @type {object}
 * @property {array} modules - sets the menu
 * @property {object} viewAllAction - sets view all menu
 * @property {string} dismissLabel - content for aria label attribute on close button
*/
export const propTypes =  {
  modules: PropTypes.array.isRequired,
  viewAllAction: PropTypes.object,
  dismissLabel: PropTypes.string
};


NavigationOverlay.propTypes = propTypes;
NavigationOverlay.displayName = 'NavigationOverlay';

export default NavigationOverlay;
